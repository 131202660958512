// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Project from "../../../../models/Project.res.js";
import * as ProjectHeader from "../_components/ProjectHeader/ProjectHeader.res.js";
import * as ProjectNavbar from "../_components/ProjectNavbar/ProjectNavbar.res.js";
import * as ProjectStickyBar from "../_components/ProjectStickyBar/ProjectStickyBar.res.js";
import * as ProjectTopNavbar from "../_components/ProjectTopNavbar/ProjectTopNavbar.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserNetworkMplsProjectScss from "./UserNetworkMplsProject.scss";
import * as UserNetworkMplsProject_Details from "./UserNetworkMplsProject_Details.res.js";

var css = UserNetworkMplsProjectScss;

function UserNetworkMplsProject(props) {
  var projectStickyBarData = props.projectStickyBarData;
  var userRole = props.userRole;
  var viewContext = props.viewContext;
  var project = props.project;
  var stickyBarUserRole = Project.Role.toRole(projectStickyBarData.userProjectRole);
  if (userRole === "Visitor") {
    return null;
  }
  var total = project.totalProposals;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(ProjectTopNavbar.make, {
                      viewContext: viewContext,
                      userRole: userRole,
                      projectStickyBarData: projectStickyBarData
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(ProjectHeader.make, {
                              title: project.title,
                              businessName: project.businessName,
                              userRole: stickyBarUserRole,
                              projectId: project.id,
                              viewContext: viewContext,
                              projectStatus: projectStickyBarData.project.status
                            }),
                        JsxRuntime.jsx(ProjectNavbar.make, {
                              activeTab: "Details",
                              userRole: stickyBarUserRole,
                              projectId: project.id,
                              totalProposals: total !== undefined ? total : 0,
                              viewContext: viewContext
                            }),
                        JsxRuntime.jsx(UserNetworkMplsProject_Details.make, {
                              project: project,
                              concierges: props.concierges,
                              userRole: stickyBarUserRole,
                              estimatedDates: props.estimatedDates
                            }),
                        JsxRuntime.jsx(ProjectStickyBar.make, {
                              userRole: stickyBarUserRole,
                              projectStickyBarData: projectStickyBarData,
                              canSendProposals: props.canSendProposals,
                              viewContext: viewContext,
                              desktop: props.desktop,
                              tablet: props.tablet,
                              mobile: props.mobile
                            })
                      ],
                      className: css.contentWrapper
                    })
              ],
              className: css.container
            });
}

var make = UserNetworkMplsProject;

export {
  css ,
  make ,
}
/* css Not a pure module */
