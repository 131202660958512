export const RecaptchaSitekey = '6LfC-FYUAAAAAE7ZjgpKKxqsgJW72n5gskwIx2oe';

export const DCLinkedInURL = 'https://www.linkedin.com/company/3567716/';
export const DCTwitterURL = 'https://twitter.com/datacenterscom?lang=en';
export const DCYouTubeURL = 'https://www.youtube.com/user/datacenterscom';
export const DCFacebookURL = 'https://www.facebook.com/datacenters/';

export const DCContactNumber = '+1 833-471-7100';
export const DCContactNumberHRef = 'tel:+1-833-471-7100';

export const GoogleMapAPI = process.env.GOOGLE_MAPS_API;
export const GoogleMapLibraries = ['drawing'];
export const ImageErrorPlaceholder = CloudinaryCloudName =>
  `https://res.cloudinary.com/${CloudinaryCloudName}/image-not-found.png`;
export const VideoUploadLimit = 6;
