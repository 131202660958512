// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconMapView(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Map View";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("path", {
                    d: "M0,8 L3,8 L3.5,10.0002957 L2,10.0002957 L2,14 L14,14 L14,10.0002957 L12.5,10.0002957 L13,8 L16,8 L16,16 L0,16 L0,8 Z M8,13 C4.66666667,10.8409492 3,8.1742825 3,5 C3,2.23857625 5.23857625,0 8,0 C10.7614237,0 13,2.23857625 13,5 C13,8.1742825 11.3333333,10.8409492 8,13 Z M8,6 C9.1045695,6 10,5.1045695 10,4 C10,2.8954305 9.1045695,2 8,2 C6.8954305,2 6,2.8954305 6,4 C6,5.1045695 6.8954305,6 8,6 Z",
                    fill: Icon.mapColor(props.color)
                  })
            });
}

var make = IconMapView;

export {
  make ,
}
/* Icon Not a pure module */
