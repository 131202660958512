// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectProviderTileScss from "./ProjectProviderTile.scss";

var css = ProjectProviderTileScss;

function ProjectProviderTile(props) {
  var __className = props.className;
  var provider = props.provider;
  var className = __className !== undefined ? __className : "";
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("img", {
                      className: css.logo,
                      alt: provider.name,
                      src: provider.providerImage
                    }),
                JsxRuntime.jsx("h5", {
                      children: provider.name,
                      className: css.providerName
                    })
              ],
              className: Cx.cx([
                    css.container,
                    className
                  ])
            });
}

var make = ProjectProviderTile;

export {
  css ,
  make ,
}
/* css Not a pure module */
