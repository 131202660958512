// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserProjectDetailsPanel from "../_components/UserProjectDetailsPanel/UserProjectDetailsPanel.res.js";
import * as UserNetworkMplsProject_Details_Location from "./UserNetworkMplsProject_Details_Location.res.js";
import * as ProjectRequiredProducts_CheckmarkableOptions from "../_components/ProjectRequiredProducts/ProjectRequiredProducts_CheckmarkableOptions.res.js";
import * as UserNetworkMplsProject_Details_RequiredProductsScss from "./UserNetworkMplsProject_Details_RequiredProducts.scss";
import * as UserNetworkMplsProject_Details_RequiredProducts_ProductServiceDescription from "./UserNetworkMplsProject_Details_RequiredProducts_ProductServiceDescription.res.js";

var css = UserNetworkMplsProject_Details_RequiredProductsScss;

function panelHeading(panelHeading$1) {
  return JsxRuntime.jsx("h4", {
              children: panelHeading$1,
              className: css.panelHeading
            });
}

function UserNetworkMplsProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptions(props) {
  var options = props.options;
  if (options !== undefined && options.length !== 0) {
    return JsxRuntime.jsx("div", {
                children: JsxRuntime.jsx(ProjectRequiredProducts_CheckmarkableOptions.make, {
                      options: options,
                      cssStart: "StayShaded"
                    }),
                className: css.propertiesContainer
              });
  } else {
    return null;
  }
}

var CheckmarkableOptions = {
  make: UserNetworkMplsProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptions
};

function UserNetworkMplsProject_Details_RequiredProducts_ProductPanel(props) {
  var __isExpanded = props.isExpanded;
  var networkMplsConfiguration = props.networkMplsConfiguration;
  var isExpanded = __isExpanded !== undefined ? __isExpanded : false;
  var requirements = networkMplsConfiguration.additionalRequirements;
  return JsxRuntime.jsx(UserProjectDetailsPanel.make, {
              className: css.panel,
              heading: props.heading,
              isExpanded: isExpanded,
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsxs(JsxRuntime.Fragment, {
                            children: [
                              JsxRuntime.jsx(UserNetworkMplsProject_Details_Location.make, {
                                    networkMplsConfiguration: networkMplsConfiguration
                                  }),
                              JsxRuntime.jsx("p", {
                                    children: "MPLS Service",
                                    className: css.productName
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(UserNetworkMplsProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                                  label: "Access Type",
                                                  value: networkMplsConfiguration.accessType
                                                }),
                                            className: Cx.cx([
                                                  css.propertyLeft,
                                                  css.stayShaded
                                                ])
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(UserNetworkMplsProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                                  label: "Port Speed",
                                                  value: networkMplsConfiguration.portSpeed
                                                }),
                                            className: Cx.cx([
                                                  css.propertyRight,
                                                  css.switchShaded
                                                ])
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(UserNetworkMplsProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                                  label: "Class Of Service",
                                                  value: Belt_Option.getWithDefault(networkMplsConfiguration.classOfService, "")
                                                }),
                                            className: Cx.cx([
                                                  css.propertyRight,
                                                  css.switchLight
                                                ])
                                          })
                                    ],
                                    className: css.propertiesContainer
                                  })
                            ]
                          }),
                      JsxRuntime.jsx(UserNetworkMplsProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptions, {
                            options: networkMplsConfiguration.mplsServices
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("p", {
                                    children: "Additional Service Requirements",
                                    className: css.productName
                                  }),
                              requirements !== undefined ? JsxRuntime.jsx("div", {
                                      children: requirements
                                    }) : null
                            ],
                            className: css.requirementsBlock
                          })
                    ],
                    className: css.panelBody
                  })
            });
}

var make = UserNetworkMplsProject_Details_RequiredProducts_ProductPanel;

export {
  css ,
  panelHeading ,
  CheckmarkableOptions ,
  make ,
}
/* css Not a pure module */
